
import { defineComponent } from 'vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'

export default defineComponent({
  name: 'MailNotConfirmedSection',
  data () {
    return {
      isModalOpen: false,
      dataPopup: 'Ссылка для подверждения емейла была отправлена на почту. Пожалуйста, перейдите по ссылке в письме. Если не нашли письма - проверьте папку спам.'
    }
  },
  components: {
    DefaultPopup
  },
  methods: {
    resendEmail () {
      this.$store.dispatch('getPayments/resend')
      this.isModalOpen = true
    },
    handleCloseModal () {
      this.$router.push('/dashboard/home')
      this.$store.dispatch('auth/logout').then(
        () => {
          this.$router.push('/')
        }
      )
    }
  }
})


import { defineComponent } from 'vue'
import SecondLayout from '@/templates/SecondLayout.vue'
import MailNotConfirmedSection from '@/components/sections/MailNotConfirmedSection.vue'

export default defineComponent({
  name: 'MailNotConfirmed',
  components: {
    SecondLayout,
    MailNotConfirmedSection
  }
})
